import React from "react"
import MainLayout from "@/layouts/MainLayout"
import Privacy from "@/components/Privacy"

export default () => {
  return (
    <MainLayout>
      <Privacy />
    </MainLayout>
  )
}